// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

require.context("../images", true, /\.(gif|jpg|png|svg|mp4|webm|ogv)$/i)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("../stylesheets/application.scss")

import "./lightbox"
import "@fortawesome/fontawesome-free/js/all"

// The current version of @rails/actiontext doesn't play well with webpack:
// https://github.com/rails/rails/issues/43973
//
// As a workaround we are using the solution proposed here (instead of `import "@rails/actiontext`):
// https://github.com/rails/rails/issues/43973#issuecomment-1001877734
import { AttachmentUpload } from "@rails/actiontext/app/javascript/actiontext/attachment_upload"

addEventListener("trix-attachment-add", (event) => {
  const { attachment, target } = event

  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target)
    upload.start()
  }
})
